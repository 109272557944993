import React, { useState } from "react";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import { useRouteMatch } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import ChainDetails from "./ChainDetails";
import ChainRemoveModal from "./ScheduleRemoveModal";
import CardCommon from "../../../../components/card/CardCommon";
import ScheduleEditModal, {
  generateDailyTimeNodeUsingMinutes,
  getDateObject,
} from "./ScheduleEditModal";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors, HttpStatus } from "../../../../utils/enum";
import ScheduleCreateModal from "./ScheduleCreateModal";
import {
  createNewScheduledTask,
  fetchAllScheduledTask,
  removeScheduledTask,
  updateScheduledTask,
} from "../../../../services/locationApp/scheduledTaskConfiguration";
import { AxiosError } from "axios";
import ScheduleRemoveModal from "./ScheduleRemoveModal";
import { CustomTheme } from "../../../../types/customTheme";

export interface StockConfigurationNodeProps {
  scheduleTaskNode: any;
  setScheduleTaskNode: any;
}

const daysObj: any = {
  0: "-",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

/**
 * Component: ScheduleTaskConfigurationNode
 *
 * Description:
 * This component represents a node in the Stock Configuration page.
 * It provides controls for managing stock-related configurations, such as enabling/disabling stock,
 * enabling/disabling master location, selecting the department, and saving changes.
 **/

const ScheduleTaskConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({ scheduleTaskNode, setScheduleTaskNode }) => {
  const [openChainCreateModal, setOpenChainCreateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [scheduledId, setScheduledId] = useState("");
  const [error, setError] = useState("");
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [editScheduleDetails, setEditScheduleDetails] = useState<any>({});
  const [success, setSuccess] = useState("");
  const [selectedChain, setSelectedChain] = useState<any>({});
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const match: any = useRouteMatch();

  const handleCreateNewSchedule = async (formData: any) => {
    try {
      setIsLoading(true);
      const res = await createNewScheduledTask(
        match.params.locationId,
        formData,
      );
      const responseData = res.data.data;
      responseData.data = JSON.stringify(res.data.data.data);
      const cloneScheduleTaskNode = _.cloneDeep(scheduleTaskNode);
      cloneScheduleTaskNode.push(responseData);

      setScheduleTaskNode(cloneScheduleTaskNode);
      setSuccess(SUCCESSFULLY_CREATED);
      setIsLoading(false);
      setIsOpenCreateModal(false);
    } catch (error) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleGetScheduleTask = async (data: any) => {
    try {
      const res = await fetchAllScheduledTask(match.params.locationId);
      const filterNode = res?.data?.filter(
        (data: any) => data.id === editScheduleDetails.id,
      );
      handleUpdateSchedule(data, filterNode[0].version);
    } catch (error) {
      setError(ERROR_MESSAGE_UPDATING_ERROR);
      setIsLoading(false);
    }
  };

  const handleUpdateSchedule = async (data: any, version?: any) => {
    try {
      setIsLoading(true);
      if (version) {
        data.version = version;
      }
      const res = await updateScheduledTask(match.params.locationId, data);

      if (res.data.status === HttpStatus.CONFLICT_409) {
        handleGetScheduleTask(data);
      } else {
        const responseData = res.data;
        responseData.data = JSON.stringify(res.data.data);

        setSuccess(SUCCESSFULLY_UPDATED);

        const updatedScheduleTaskNode = scheduleTaskNode.map((item: any) => {
          // Update the specific item based on the ID
          if (item.id === editScheduleDetails.id) {
            return { ...item, ...responseData }; // Merge updated data into the item
          }
          return item; // Keep other items unchanged
        });

        setScheduleTaskNode(updatedScheduleTaskNode);
        setIsOpenEditModal(false);
      }
      console.log("asdfsdghfdhgh", res);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      const err: any = error as AxiosError;
      console.log("asdfsdghfdhgh", err);
      if (err.response.status === HttpStatus.CONFLICT_409) {
        handleGetScheduleTask(data);
      } else {
        setError(ERROR_MESSAGE_UPDATING_ERROR);
      }
    }
    setIsLoading(false);
  };

  const handleOpenDeleteModal = (id: any) => {
    setScheduledId(id);
    setIsOpenDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);

      const res = await removeScheduledTask(
        match.params.locationId,
        scheduledId,
      );
      setSuccess(SUCCESSFULLY_DELETED);

      const cloneScheduleTaskNode = _.cloneDeep(scheduleTaskNode);
      const filteredScheduleTaskNode = cloneScheduleTaskNode.filter(
        (data: any) => data.id !== scheduledId,
      );
      setScheduleTaskNode(filteredScheduleTaskNode);
      setIsOpenDeleteModal(false);
      setIsLoading(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  const handelConvertUpperCase = (str: any) => {
    return str
      .toString()
      .toLowerCase()
      .split("-")
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleEdit = (item: any) => {
    setIsOpenEditModal(true);
    setEditScheduleDetails(item);
  };

  const handleOpenCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  const handleEmail = (item: any) => {
    const { type, data } = item;
    if (type === "daily-summary-email") {
      const jsonParseData = JSON.parse(data);
      console.log("jsonParseData", item, jsonParseData);
      const { recipients } = jsonParseData;
      if (recipients) {
        if (recipients.length > 1) {
          return `${recipients[0]} + ${recipients.length - 1} more`;
        } else {
          return recipients[0];
        }
      }
      return "-";
    } else {
      return "-";
    }
  };

  const handleTooltip = (item: any): string => {
    const { type, data } = item;
  
    if (type === "daily-summary-email") {
      try {
        const jsonParseData = JSON.parse(data);
        console.log("jsonParseData", item, jsonParseData);
  
        const { recipients } = jsonParseData;
        if (Array.isArray(recipients) && recipients.length > 0) {
          if (recipients.length > 1) {
            // Return all recipients as a comma-separated string
            return recipients.join("\n");
          } else {
            // Return the single recipient
            return recipients[0];
          }
        }
      } catch (error) {
        console.error("Error parsing data:", error);
        return "Invalid data format";
      }
    }
  
    // Default return value for non-matching types
    return "No tooltip available";
  };

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "12px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            style={{ fontWeight: "bold" }}
            color="secondary"
            align="left"
          >
            Scheduled Task
          </Typography>

          <ButtonCommon
            style={{
              marginRight: 4,
              width: "240px",
            }}
            variant="contained"
            color={buttonColors.CREATE_BUTTON_COLOR}
            onClick={handleOpenCreateModal}
          >
            <Typography style={{ textTransform: "none", color: "white" }}>
              Add New Scheduled Task
            </Typography>
          </ButtonCommon>
        </Grid>
      </Grid>
      <CardCommon backgroundColor={"table_header_background"}>
        <Grid container style={{ minHeight: "36px", placeItems: "center" }}>
          {/* Location Header */}
          <Grid item xs={2} style={{ paddingLeft: "12px" }}>
            <Typography align="left">Execution Type</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>Execute Time</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Execution Day</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>Executed</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Info</Typography>
          </Grid>
          <Grid item xs={3} style={{ paddingRight: "110px" }}>
            <Typography align="right">Type</Typography>
          </Grid>
        </Grid>
      </CardCommon>
      <Grid container>
        {scheduleTaskNode.map((item: any, index: number) => (
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <CardCommon
              backgroundColor={
                index % 2 === 0
                  ? "entity_background"
                  : "entity_highlight_background"
              }
            >
              <Grid
                container
                style={{ minHeight: "36px", placeItems: "center" }}
              >
                <Grid item xs={2} style={{ paddingLeft: "12px" }}>
                  <Typography align="left">
                    {handelConvertUpperCase(item.executionType)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>
                    {generateDailyTimeNodeUsingMinutes(item.executeTime)?.name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>
                    {item.executionType === "daily" ||
                    item.executionType === "weekly"
                      ? daysObj[item.executionDay]
                      : getDateObject(item.executionDay)?.name}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ marginTop: "4px" }}>
                    {item.status ? (
                      <CheckCircleIcon
                        style={{
                          color: theme.palette.custom.green.dark,
                        }}
                      />
                    ) : (
                      <CloseIcon
                        style={{
                          color: theme.palette.custom.red.dark,
                        }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                <Tooltip
    title={
      <div style={{ whiteSpace: "pre-line" }}>
        {handleTooltip(item)}
      </div>
    }
  >
                    <Typography>{handleEmail(item)}</Typography>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    paddingRight: "12px",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Typography>{handelConvertUpperCase(item.type)}</Typography>
                  <Button
                    style={{
                      minWidth: "40px",
                      maxWidth: "40px",
                      marginLeft: "8px",
                    }}
                    onClick={() => handleEdit(item)}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    style={{
                      minWidth: "40px",
                      maxWidth: "40px",
                      marginLeft: "8px",
                    }}
                    onClick={() => handleOpenDeleteModal(item.id)}
                  >
                    <DeleteIcon />
                  </Button>
                </Grid>
              </Grid>
            </CardCommon>
          </Grid>
        ))}
      </Grid>

      <ScheduleEditModal
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        isLoading={isLoading}
        editScheduleDetails={editScheduleDetails}
        handleUpdateSchedule={handleUpdateSchedule}
      />

      <ScheduleCreateModal
        isOpen={isOpenCreateModal}
        setIsOpen={setIsOpenCreateModal}
        isLoading={isLoading}
        handleCreateNewSchedule={handleCreateNewSchedule}
      />

      <ScheduleRemoveModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        handleRemoveSchedule={handleDelete}
        isLoading={isLoading}
      />
      {/* <Grid container spacing={2}>
        <Grid item xs={4}>
          {Object.values(currentChain)?.length === 0 ? (
            <ButtonCommon
              onClick={handleOpenCreateNewChain}
              variant="contained"
              style={{
                fontSize: 11,
                height: "60px",
                width: "100%",
                textTransform: "none",
                marginRight: "12px",
              }}
              color={buttonColors.CREATE_BUTTON_COLOR}
            >
              <Typography variant="h6" style={{ color: "white" }}>
                Create a new chain{" "}
              </Typography>
            </ButtonCommon>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={4}>
          <ButtonCommon
            onClick={handleOpenConnectExistingChainModal}
            variant="contained"
            style={{
              fontSize: 11,
              height: "60px",
              width: "100%",
              textTransform: "none",
            }}
            disabled={
              currentChain?.["primary_location"] === match.params.locationId ||
              Object.values(currentChain)?.length >0
            }
            color={buttonColors.CREATE_BUTTON_COLOR}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Link to an existing chain
            </Typography>
          </ButtonCommon>
        </Grid>
      </Grid>

      <ChainRemoveModal
        isOpen={isOpenAlert}
        setIsOpen={setIsOpenAlert}
        handleRemoveChain={handleRemoveChain}
        isLoadingUpdate={isLoadingUpdate}
      />

      <CreateNewChain
        isOpen={openChainCreateModal}
        setIsOpen={setOpenChainCreateModal}
        handleCreate={handleCreateNewChain}
        isLoading={isLoadingChain}
      />

      <ConnectExistingChainModal
        isOpen={isOpenConnectExistingChainModal}
        setIsOpen={setIsOpenConnectExistingChainModal}
        handleCreate={handleConnectExistingChain}
        chainNode={chainNode}
        selectedChain={selectedChain}
        handleChangeMasterChain={handleChangeMasterChain}
        isLoading={isLoading}
        setSelectedChain={setSelectedChain}
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />

      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      /> */}
    </>
  );
};

// Apply the WithLoading higher-order component to the ScheduleTaskConfigurationNode component
export default ScheduleTaskConfigurationNode;
