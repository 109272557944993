/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
import {
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableInfo from "./TableInfo";
import { handlePdf } from "../convert/handlePdf";
import { handleExcel } from "../convert/handleExcel";
import { useTheme } from "@material-ui/styles";
import BreakDownOfShiftTableInfo from "./BreakDownOfShiftTableInfo";
import BankingAndSummeryInfo from "./BankingAndSummeryInfo";
import MenuButtonCommon from "../../../../../components/buttons/MenuButtonCommon";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import { getFilterListFromArrayObject } from "../../../../../utils/commonArrayMap";
import ModalTableLoading from "../../../../../components/common/Loading/ModalTableLoading";
import { CustomTheme } from "../../../../../types/customTheme";
import {
  CapitalizeFirstLetter,
  convertDecimalPoint,
  replaceCommon,
  ReplaceSpaceAfterCapitalLetter,
  replaceUnderscoreToSpace,
} from "../../../../../utils/ReplaceIcon";
import {
  convertDateFormat,
  convertTimeFormat,
} from "../../../../../utils/ConvertDateTimeFormat";
import BankingInfo from "./BankingInfo";
import { capitalizeWords } from "../../../../../utils/capitalizeWords";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    buttonStyle: {
      width: "120px",
      height: "40px",
      backgroundColor: theme.palette.custom.red.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.custom.red.dark,
      },
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      [theme.breakpoints.down("sm")]: {
        width: "80px",
        fontSize: "14px",
      },
    },
    payInChipStyle: {
      width: "72px",
      backgroundColor: theme.palette.custom.green.main,
      color: theme.palette.custom.red.contrastText,
      height: "28px",
    },
    payOutChipStyle: {
      width: "72px",
      backgroundColor: theme.palette.custom.red.main,
      color: theme.palette.custom.red.contrastText,
      height: "28px",
    },
  }),
);

const shiftReportTitleList: any = {
  banking: { id: "Currency", label: "Banking", title: "Banking" },
  summary: { id: "Title", label: "Summary", title: "Summary" },
  "other-payments": {
    id: "Payments Type",
    label: "Other Payments",
    title: "Payments Type",
  },
  "vat-audit": { id: "VAT Rate", label: "VAT Audit", title: "VAT Rate" },
  "paid-in-out": { id: "Date", label: "Pay In Out", title: "paid-in-out" },
  "Paid Out": { label: "Paid Out" },
  "cancelled-receipts": {
    id: "Date",
    label: "Cancelled Sales",
    title: "cancelled-receipts",
  },
  "void-transaction": {
    id: "Date",
    label: "Void Transactions",
    title: "void-transaction",
  },
  discount: { id: "User", label: "Discounts", title: "User" },
  mode: { id: "Mode", label: "Mode Summary", title: "Mode" },
  platform: { id: "Platform", label: "Platform Summary", title: "Platform" },
  channel: { id: "Channel", label: "Channel Summary", title: "Channel" },
};

const bankingCashUnits = {
  "1p": { currency: "1p", count: "count1p", total: "value1p" },
  "2p": { currency: "2p", count: "count2p", total: "value2p" },
  "5p": { currency: "5p", count: "count5p", total: "value5p" },
  "10p": { currency: "10p", count: "count10p", total: "value10p" },
  "20p": { currency: "20p", count: "count20p", total: "value20p" },
  "50p": { currency: "50p", count: "count50p", total: "value50p" },
  "£1": { currency: "£1", count: "count1", total: "value1" },
  "£2": { currency: "£2", count: "count2", total: "value2" },
  "£5": { currency: "£5", count: "count5", total: "value5" },
  "£10": { currency: "£10", count: "count10", total: "value10" },
  "£20": { currency: "£20", count: "count20", total: "value20" },
  "£50": { currency: "£50", count: "count50", total: "value50" },
};

export interface AvailabilitySelectionModalProps {
  handleCloseModal: any;
  open: any;
  shiftReportNode: any;
  filterDetails: any;
  isShiftSummaryReportLoading: any;
  selectedList: any;
  userList: any;
  setNodes: any;
  nodes: any;
  deviceIdErr: any;
}

const orderChannelIdList = ["eatpresto", "justeat", "ubereats", "deliveroo"];

const eatprestoIdList = ["eat_presto", "wix"];

const orderChannelList = [
  { id: "eatpresto", label: "Eat Presto" },
  { id: "justeat", label: "Just Eat" },
  { id: "ubereats", label: "Uber Eats" },
  { id: "deliveroo", label: "Deliveroo" },
];

const Generate: React.FunctionComponent<AvailabilitySelectionModalProps> = ({
  handleCloseModal,
  open,
  shiftReportNode,
  filterDetails,
  isShiftSummaryReportLoading,
  selectedList,
  userList,
  setNodes,
  nodes,
  deviceIdErr,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [headersLists, setHeadersLists] = useState<any>([
    { header: "Type", key: "type", width: 52, height: 68 },
    { header: "Amount", key: "amount", width: 52, height: 68 },
  ]);

  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [nodesPdfExcelInitial, setNodesPdfExcelInitial] = useState<any>([]);
  const [pdfHeader, setPdfHeader] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [isShowBreakdownShift, setIsShowBreakdownShift] = useState(true);
  const [initialNode, setInitialNode] = useState<any>([]);
  const openExport = Boolean(anchorEl);

  const handleVatTotal = (vatAuditData: any, vatOrGross: string) => {
    if (vatAuditData[vatOrGross].toString().includes(".")) {
      return convertDecimalPoint(vatAuditData[vatOrGross]);
    } else {
      return vatAuditData[vatOrGross];
    }
  };

  /* Adding the minus sign if it is a payOut. */
  const handlePayInOutMinusIcon = (payInOut: string, amount: any) => {
    if (payInOut === "pay_in") {
      return convertDecimalPoint(amount);
    } else {
      return `-${convertDecimalPoint(amount)}`;
    }
  };

  /* Creating a chip according to payIn and payOut. */
  const handlePayInOutChip = (payInOrOut: any) => {
    const label = replaceUnderscoreToSpace(payInOrOut);
    if (payInOrOut === "pay_in") {
      return (
        <Chip
          label={label}
          style={{
            width: "88px",
            backgroundColor: theme.palette.custom.green.main,
            color: theme.palette.custom.red.contrastText,
          }}
          className={classes.payInChipStyle}
        />
      );
    } else {
      return (
        <Chip
          label={label}
          style={{
            width: "88px",
            backgroundColor: theme.palette.custom.red.main,
            color: theme.palette.custom.red.contrastText,
          }}
          className={classes.payOutChipStyle}
        />
      );
    }
  };

  /* Handle Break Down Shift Table Data */
  const handleBreakDownShift = () => {
    // Define Break Down Shift Table header
    let basedOnShiftHeader: any = [];
    if (deviceIdErr) {
      basedOnShiftHeader = [
        "Location",
        "Terminal",
        "Start Date",
        "End Date",
        "Duration",
      ];
    } else {
      basedOnShiftHeader = [
        "Location",
        "Terminal",
        "TID",
        "Start Date",
        "End Date",
        "Duration",
      ];
    }
    let basedOnShiftTableName = "";
    // The name of the basedOnShift table is changed according to the length of the selected shift.
    if (selectedList.length > 1) {
      basedOnShiftTableName = `This report includes  information from the ${selectedList.length} shifts`;
    } else {
      basedOnShiftTableName =
        "This report includes  information from the following shift";
    }

    return { basedOnShiftHeader, basedOnShiftTableName };
  };

  /* Handle Discount Table Data */
  const handleDiscountTable = (
    discountData: any,
    index: any,
    nodeData: any,
    total: any,
    ShiftDetails: any,
  ) => {
    const { orderCount, userId, totalAmount, discountAmount } = discountData;

    // Get user name using user id
    const selectedUser = getFilterListFromArrayObject(
      userList,
      userId.toString(),
    );
    let userName = "";
    if (!_.isEmpty(selectedUser)) {
      userName = selectedUser[0].label;
    } else {
      userName = userId;
    }
    // Entering data into the body of the discount table.
    nodeData.push({
      User: userName,
      "Order Count": orderCount,
      "Discounted Amount": convertDecimalPoint(discountAmount),
      "Order Total": convertDecimalPoint(totalAmount),
    });

    // Calculating the sum of the order, count and discount amount.
    total["Order Total"] = total["Order Total"] + totalAmount;
    total["Order Count"] = total["Order Count"] + orderCount;
    total["Discounted Amount"] = total["Discounted Amount"] + discountAmount;

    // Converting decimal point of the order, count and discount amount.
    if (index + 1 === ShiftDetails.length) {
      total["Order Total"] = convertDecimalPoint(total["Order Total"]);
      total["Discounted Amount"] = convertDecimalPoint(
        total["Discounted Amount"],
      );
    }
    return { nodeData, total };
  };

  /* Handle Void Transaction and Cancelled Sale Table Data */
  const handleVoidTransactionAndCancelledSaleTable = (
    voidTransactionOrCancelledReceiptData: any,
    index: any,
    nodeData: any,
    total: any,
    ShiftDetails: any,
  ) => {
    const { date, userId, amount } = voidTransactionOrCancelledReceiptData;

    // Get user name using user id
    const selectedUser = getFilterListFromArrayObject(userList, userId);
    let userName = "";
    if (!_.isEmpty(selectedUser)) {
      userName = selectedUser[0].label;
    } else {
      userName = userId;
    }
    // Entering data into the body of the void transaction and cancelled sale table.
    nodeData.push({
      Date: convertDateFormat(date),
      Time: convertTimeFormat(date),
      User: userName,
      Amount: convertDecimalPoint(amount),
    });

    if (total[userName] === undefined) {
      total[userName] = 0;
    }

    // Each user's total amount is calculated separately.
    total[userName] = total[userName] + amount;

    // Calculating the sum of the amount.
    total["Amount"] = total["Amount"] + amount;
    if (index + 1 === ShiftDetails.length) {
      total["Amount"] = convertDecimalPoint(total["Amount"]);
      total[userName] = convertDecimalPoint(total[userName]);
    }
    return { nodeData, total };
  };

  /* Handle Void Transaction and Cancelled Sale Table Data */
  const handlePaidInOutTable = (
    paidInOutData: any,
    index: any,
    nodeData: any,
    total: any,
    ShiftDetails: any,
  ) => {
    const { date, payInOut, amount, type, remarks } = paidInOutData;

    // Entering data into the body of the pay in out table.
    nodeData.push({
      Date: convertDateFormat(date),
      Time: convertTimeFormat(date),
      Type: type,
      "Pay In/Out": handlePayInOutChip(payInOut),
      Remarks: remarks,
      Amount: handlePayInOutMinusIcon(payInOut, amount),
    });

    if (total[type] === undefined) {
      total[type] = 0;
    }
    if (total["payIn"] === undefined) {
      total["payIn"] = 0;
    }
    if (total["payOut"] === undefined) {
      total["payOut"] = 0;
    }

    // Getting the sum of types separately. It is added if it is a payIn and subtracted if it is a payOut.
    if (payInOut === "pay_in") {
      total[type] = total[type] + amount;
      total["payIn"] = total["payIn"] + amount;
    } else {
      total[type] = total[type] - amount;
      total["payOut"] = total["payOut"] - amount;
    }

    // Getting the sum of payIn and payOut separately. It is added if it is a payIn and subtracted if it is a payOut.
    if (payInOut === "pay_in") {
      total["Amount"] = total["Amount"] + amount;
    } else if (payInOut === "pay_out") {
      total["Amount"] = total["Amount"] - amount;
    }

    //Converting decimal point of the totals
    if (index + 1 === ShiftDetails.length) {
      total["Amount"] = convertDecimalPoint(total["Amount"]);
      total["payIn"] = convertDecimalPoint(total["payIn"]);
      total["payOut"] = convertDecimalPoint(total["payOut"]);
    }
    return { nodeData, total };
  };

  // const output = {
  //  "deliverect": { channel: "deliverect", platform: "online", quantity: 1, totalAmount: 50 },
  //  "eatpresto": { channel: "eatpresto", platform: "online", quantity: 3, totalAmount: 6 },
  // };

  const handleBankingTable = (
    bankingData: any,
    nodeData: any,
    total: any,
    objectKey: any,
    headers: any,
    index: any,
    shiftReportNode: any,
  ) => {
    if (Object.values(bankingData)?.length > 0) {
      if (!headers) {
        headers = [];
        headers.push("Count", "Total");
      } else {
        headers.push("Count", "Total");
      }
      let countTotal = 0;
      let totals = 0;
      Object.values(bankingCashUnits).map((data: any) => {
        countTotal = countTotal + parseFloat(bankingData[data.count]);
        totals = totals + parseFloat(bankingData[data.total]);
        nodeData.push({
          Currency: data.currency,
          Count: bankingData[data.count],
          Total: parseFloat(bankingData[data.total]).toFixed(2),
        });
      });
      const objPayIn: any = {};
      const objPayOut: any = {};
      shiftReportNode["paid-in-out"].map((data: any) => {
        if (data.payInOut === "pay_in") {
          if (!objPayIn[data.type]) {
            objPayIn[data.type] = 0;
          }
          objPayIn[data.type] = parseFloat(
            parseFloat(objPayIn[data.type]) + data.amount,
          ).toFixed(2);
        } else {
          if (!objPayOut[data.type]) {
            objPayOut[data.type] = 0;
          }
          objPayOut[data.type] = parseFloat(
            parseFloat(objPayOut[data.type]) + data.amount,
          ).toFixed(2);
        }
      });

      nodeData["payInOut"] = {};

      const outputPayIn = Object.entries(objPayIn).map(([key, value]) => ({
        type: key,
        amount: value,
      }));
      const totalPayIn = outputPayIn.reduce(
        (sum, item: any) => sum + parseFloat(item.amount),
        0,
      );

      const outputPayOut = Object.entries(objPayOut).map(([key, value]) => ({
        type: key,
        amount: value,
      }));
      const totalPayOut = outputPayOut.reduce(
        (sum, item: any) => sum + parseFloat(item.amount),
        0,
      );
      outputPayIn.push({ type: "Total Added", amount: totalPayIn.toFixed(2) });
      outputPayOut.push({
        type: "Total Deducted",
        amount: totalPayOut.toFixed(2),
      });

      nodeData["payInOut"]["payIn"] = outputPayIn;
      nodeData["payInOut"]["payOut"] = outputPayOut;

      const totalCash = shiftReportNode["summary"]?.cashPayments || 0;
      const onlineOrderCashAmount =
        parseFloat(
          shiftReportNode["other-payments"]["online-order-cash"]?.amount,
        ) || 0;
      const totalOnlineOrderCash = onlineOrderCashAmount;
      const totalCashOnlineOrderCash =
        parseFloat(totalCash) + totalOnlineOrderCash;
      const paidInTotalAmount =
        parseFloat(shiftReportNode["summary"]?.paidInTotal) || 0;

      const paidOutTotalAmount =
        parseFloat(shiftReportNode["summary"]?.paidOutTotal) || 0;

      const startFloat = shiftReportNode["banking"]?.startFloat || 0;
      const safeDrop = shiftReportNode["banking"]?.safeDrop || 0;

      const targetCash = parseFloat(
        (
          totalCashOnlineOrderCash +
          paidInTotalAmount +
          startFloat -
          (paidOutTotalAmount + safeDrop)
        ).toString(),
      ).toFixed(2);

      if (!nodeData["info"]) {
        nodeData["info"] = [];
      }

      nodeData["info"].push({
        type: "Declared Cash",
        amount: parseFloat(shiftReportNode.banking.declaredCash).toFixed(2),
      });

      nodeData["info"].push({ type: "Target Cash", amount: targetCash });

      nodeData["info"].push({
        type: "Deficit / Surplus",
        amount: parseFloat(
          (
            parseFloat(shiftReportNode.banking.declaredCash) -
            parseFloat(targetCash)
          ).toString(),
        ).toFixed(2),
      });

      nodeData["info"].push({
        type: "Cash to Bank",
        amount: parseFloat(
          (
            parseFloat(shiftReportNode.banking.declaredCash) - startFloat
          ).toString(),
        ).toFixed(2),
      });

      const cloneDate: any = _.cloneDeep(shiftReportNode["takings"]);
      const takingsList: any = [];

      const onlineOrderCardAmount =
        parseFloat(
          shiftReportNode["other-payments"]["online-order-card"]?.amount,
        ) || 0;
      const totalOnlineOrderCard = onlineOrderCardAmount;

      const totalCard = shiftReportNode["summary"]?.cardPayments || 0;
      orderChannelList.forEach((data: any) => {
        let matched = false; // Flag to track if the second condition matches
        let data2 = {};
        for (let i = 0; i < cloneDate.length; i++) {
          const data1 = cloneDate[i];
          data2 = data1;

          if (data.id === data1.channel && data1.platform === "online") {
            takingsList.push({
              type: data.label,
              amount: parseFloat(data1.totalAmount).toFixed(2),
            });
            cloneDate.splice(i, 1); // Remove item after pushing
            i--; // Adjust index after removal
            matched = true; // Mark as matched
          } else if (
            eatprestoIdList.includes(data1.channel) &&
            data1.platform === "online"
          ) {
            takingsList.push({
              type: "Eatpresto",
              amount: parseFloat(data1.totalAmount).toFixed(2),
            });
            cloneDate.splice(i, 1); // Remove item after pushing
            i--; // Adjust index after removal
            matched = true; // Mark as matched
          }
        }

        // If no match for this iteration, add entry with amount 0
        if (!matched) {
          takingsList.push({
            type: data.label, // Use current data's label
            amount: "0.00", // Add amount 0
          });
        }
      });

      cloneDate.map((data: any) => {
        if (data.platform === "online") {
          takingsList.push({
            type: capitalizeWords(data.channel),
            amount: parseFloat(data.totalAmount).toFixed(2),
          });
        }
      });

      const output = Object.values(
        takingsList.reduce((acc: any, curr: any) => {
          if (!acc[curr.type]) {
            acc[curr.type] = { ...curr };
          } else {
            acc[curr.type].amount = parseFloat(
              (
                parseFloat(acc[curr.type].amount) + parseFloat(curr.amount)
              ).toString(),
            ).toFixed(2);
          }
          return acc;
        }, {}),
      );

      output.push(
        {
          type: "Cash",
          amount: parseFloat(
            (
              parseFloat(totalCash) +
              parseFloat(totalOnlineOrderCash.toString())
            ).toString(),
          ).toFixed(2),
        },
        {
          type: "Card",
          amount: parseFloat(
            (
              parseFloat(totalCard) +
              parseFloat(totalOnlineOrderCard.toString())
            ).toString(),
          ).toFixed(2),
        },
      );

      if (Object.keys(shiftReportNode?.["other-payments"]).length > 0) {
        Object.entries(shiftReportNode?.["other-payments"]).forEach(
          ([key, value]: any, index: number) => {
            if (
              key != "paid-online-order" &&
              key !== "online-order-card" &&
              key !== "online-order-cash"
            ) {
              if (key === "paid-by-link") {
                output.push({
                  type: "Paid by Link",
                  amount: parseFloat(value.amount).toFixed(2),
                });
              } else {
                output.push({
                  type: capitalizeWords(key),
                  amount: parseFloat(value.amount).toFixed(2),
                });
              }
            }
          },
        );
      }

      output.push({
        type: "Total Takings",
        amount: parseFloat(shiftReportNode.summary.totalPayments).toFixed(2),
      });
      nodeData["takings"] = output;

      total["Currency"] = "Declared Cash Total";
      total["Count"] = "";
      total["Total"] = parseFloat(shiftReportNode.banking.declaredCash).toFixed(
        2,
      );
    }

    return { nodeData, total, headers, index };
  };

  /* Handle Banking Table Data */
  const handleSummaryTable = (
    bankingData: any,
    nodeData: any,
    total: any,
    objectKey: any,
    header: any,
    index: any,
    shiftReportNode: any,
  ) => {
    let bankingObject: any = {};
    const onlineOrderCashAmount =
      parseFloat(
        shiftReportNode["other-payments"]["online-order-cash"]?.amount,
      ) || 0;
    const totalOnlineOrderCash = onlineOrderCashAmount;

    const onlineOrderCardAmount =
      parseFloat(
        shiftReportNode["other-payments"]["online-order-card"]?.amount,
      ) || 0;
    const totalOnlineOrderCard = onlineOrderCardAmount;
    if (index === 0) {
      header.push("Amount");
    }

    if (objectKey === "cashPayments") {
      nodeData.push({
        Title: "Cash Payments In-Store",
        Amount: convertDecimalPoint(bankingData),
      });
      nodeData.push({
        Title: "Cash Payments Online",
        Amount: totalOnlineOrderCash,
      });
    } else if (objectKey === "cardPayments") {
      nodeData.push({
        Title: "Card Payments In-Store",
        Amount: convertDecimalPoint(bankingData),
      });
      nodeData.push({
        Title: "Card Payments Online",
        Amount: totalOnlineOrderCard,
      });
    } else if (objectKey === "otherPayments") {
      const totalCash = shiftReportNode["summary"]?.cashPayments || 0;
      const totalCard = shiftReportNode["summary"]?.cardPayments || 0;

      nodeData.push({
        Title: "Total Cash Payments",
        Amount: parseFloat(
          (
            parseFloat(totalCash) + parseFloat(totalOnlineOrderCash.toString())
          ).toString(),
        ).toFixed(2),
      });

      nodeData.push({
        Title: "Total Card Payments",
        Amount: parseFloat(
          (
            parseFloat(totalCard) + parseFloat(totalOnlineOrderCard.toString())
          ).toString(),
        ).toFixed(2),
      });
      console.log(
        "asddddddddddddddfsgfg",
        bankingData, "aaa",
        totalOnlineOrderCash, "bbbb",
        totalOnlineOrderCard, "ccc",
        parseFloat(
          (
            parseFloat(convertDecimalPoint(bankingData)) -
            totalOnlineOrderCash -
            totalOnlineOrderCard
          ).toString(),
        ).toFixed(2),
      );
      nodeData.push({
        Title: "Other Payments",
        Amount: parseFloat(
          (
            parseFloat(convertDecimalPoint(bankingData)) -
            totalOnlineOrderCash -
            totalOnlineOrderCard
          ).toString(),
        ).toFixed(2),
      });
    } else {
      // Capitalize first letter of the key.
      const capitalizeBankingTitle = CapitalizeFirstLetter(objectKey);

      // Entering data into the body of the banking table.
      bankingObject["Title"] = ReplaceSpaceAfterCapitalLetter(
        capitalizeBankingTitle,
      );
      bankingObject["Amount"] = convertDecimalPoint(bankingData);
      nodeData.push(bankingObject);
    }
    total = [];

    if (objectKey === "totalPayments") {
      const totalCash = shiftReportNode["summary"]?.cashPayments || 0;
      const total = parseFloat(totalCash) + totalOnlineOrderCash;
      const paidInTotalAmount =
        parseFloat(shiftReportNode["summary"]?.paidInTotal) || 0;

      const paidOutTotalAmount =
        parseFloat(shiftReportNode["summary"]?.paidOutTotal) || 0;

      const startFloat = shiftReportNode["banking"]?.startFloat || 0;
      const safeDrop = shiftReportNode["banking"]?.safeDrop || 0;

      const targetCash = parseFloat(
        (
          total +
          paidInTotalAmount +
          startFloat -
          (paidOutTotalAmount + safeDrop)
        ).toString(),
      ).toFixed(2);

      nodeData.push({
        Title: "Starting Float",
        Amount: startFloat,
      });

      nodeData.push({
        Title: "Target Cash",
        Amount: targetCash,
      });
    }

    return { nodeData, total, header, index };
  };

  /* Handle Mode Table Data */
  const handleModeTable = (
    modeData: any,
    index: any,
    nodeData: any,
    headers: any,
    total: any,
    key: any,
    medeKey: any,
    modeLength: any,
  ) => {
    if (index === 0) {
      // Set the header of mode table
      headers.push("Count", "Discount", "Amount");
      total["Count"] = 0;
      total["Discount"] = 0;
      total["Amount"] = 0;
    }

    // Entering data into the object.
    let modeNodeObject: any = {};
    modeNodeObject["Count"] = modeData["count"];
    modeNodeObject["Discount"] = convertDecimalPoint(modeData["discount"]);
    modeNodeObject["Amount"] = convertDecimalPoint(modeData["amount"]);

    // Calculating the sum of the count, discount, and amount.
    total["Count"] = total["Count"] + modeData["count"];
    total["Discount"] = total["Discount"] + modeData["discount"];
    total["Amount"] = total["Amount"] + modeData["amount"];

    //Converting decimal point of the totals
    if (index + 1 === modeLength) {
      total["Discount"] = convertDecimalPoint(total["Discount"]);
      total["Amount"] = convertDecimalPoint(total["Amount"]);
    }

    const removeUnderScoreString = replaceUnderscoreToSpace(medeKey);
    const arrSting = removeUnderScoreString.split(" ");

    // Capitalizing the first letter of each word.
    for (let i = 0; i < arrSting.length; i++) {
      arrSting[i] = CapitalizeFirstLetter(arrSting[i]);
    }
    const capitalizeKey = arrSting.join(" ");

    // Entering the data entered into the above object into the body of the mode table.
    nodeData.push({
      [shiftReportTitleList[key].title]: capitalizeKey,
      ...modeNodeObject,
    });

    return { nodeData, headers, total };
  };

  /* Handle Vat Audit Table Data */
  const handleVatAuditTable = (
    vatAuditData: any,
    index: any,
    nodeData: any,
    headers: any,
    total: any,
    key: any,
    vatAuditKey: any,
  ) => {
    if (index === 0) {
      // Set the header of mode table
      headers.push("VAT", "Total");
    }

    let vatAuditNodeObject: any = {};
    // Entering data into the object.
    if (vatAuditKey !== "total") {
      vatAuditNodeObject["VAT"] = parseFloat(vatAuditData["vat"]).toFixed(2);
      vatAuditNodeObject["Total"] = parseFloat(vatAuditData.gross).toFixed(2);
    } else {
      total["VAT"] = parseFloat(handleVatTotal(vatAuditData, "vat")).toFixed(2);
      total["Total"] = parseFloat(
        handleVatTotal(vatAuditData, "gross"),
      ).toFixed(2);
    }

    // When the vat rate is 12500, its key will be changed to 12.5.
    if (vatAuditKey === "12500" || vatAuditKey === "12500.00") {
      if (vatAuditKey !== "total") {
        nodeData.push({
          [shiftReportTitleList[key].id]: "12.5%",
          ...vatAuditNodeObject,
        });
      }
    } else {
      // Entering the data entered into the above object into the body of the vat audit table.
      if (vatAuditKey !== "total") {
        nodeData.push({
          [shiftReportTitleList[key].title]: `${replaceCommon(
            vatAuditKey,
            ".00",
            "",
          )}%`,
          ...vatAuditNodeObject,
        });
      }
    }
    return { nodeData, headers, total };
  };

  /* Handle Shift Summary Table Data */
  const handleShiftSummery = (
    shiftData: any,
    index: any,
    nodeData: any,
    headers: any,
    total: any,
    ShiftDetails: any,
    key: any,
    shiftKey: any,
    count: any,
  ) => {
    if (shiftKey !== "online-order-cash" && shiftKey !== "online-order-card") {
      let shiftObject: any = {};

      Object.keys(shiftData).map((data: any) => {
        if (count === 0) {
          // Set the header of mode table
          if (!headers.includes(CapitalizeFirstLetter(data))) {
            headers.push(CapitalizeFirstLetter(data));
          }
        }
        const capitalizeKey = CapitalizeFirstLetter(data);
        // Entering data into the object.
        if (data === "amount") {
          shiftObject[capitalizeKey] = convertDecimalPoint(shiftData[data]);
        } else {
          shiftObject[capitalizeKey] = shiftData[data];
        }
        if (total[capitalizeKey] === undefined || isNaN(total[capitalizeKey])) {
          total[capitalizeKey] = 0;
        }

        // Calculating the sum of the data.
        const sum = total[capitalizeKey] + shiftData[data];

        if (data === "count") {
          total[capitalizeKey] = sum;
        } else {
          total[capitalizeKey] = sum;
          //Converting decimal point of the totals
          if (Object.keys(ShiftDetails).length === index + 1) {
            total[capitalizeKey] = convertDecimalPoint(sum);
          }
        }
      });
      count = count + 1;
      // Entering the data entered into the above object into the body of the Shift Summary table.
      if (shiftKey !== "online-order-cash") {
        nodeData.push({
          [shiftReportTitleList[key].title]:
            shiftKey.charAt(0).toUpperCase() + shiftKey.slice(1),
          ...shiftObject,
        });
      }
    }
    total["Amount"] = parseFloat(parseFloat(total["Amount"]).toFixed(2));
    return { nodeData, headers, total };
  };

  /* When the value of shiftDetails is empty, this function returns the data for the table. */
  const handleEmptyShiftDetails = (key: any) => {
    let emptyHeader: any = [];
    let emptyNodeData: any = [];
    let emptyTotal: any = [];
    if (key === "other-payments") {
      emptyHeader.push(shiftReportTitleList[key].id, "Amount", "Count");
      emptyNodeData.push({
        [shiftReportTitleList[key].id]: "",
        Amount: "No Other Payments",
        Count: "",
      });
      emptyTotal["Amount"] = "0.00";
      emptyTotal["Count"] = "0.00";
    } else if (key === "vat-audit") {
      emptyHeader.push(shiftReportTitleList[key].id, "VAT", "Total");
      emptyNodeData.push({
        [shiftReportTitleList[key].id]: "",
        VAT: "No VAT Audits",
        Total: "",
      });
      emptyTotal["VAT"] = "0.00";
      emptyTotal["Total"] = "0.00";
    } else if (key === "mode" || key === "platform" || key === "channel") {
      emptyHeader.push(shiftReportTitleList[key].id, "Count", "Amount");
      emptyNodeData.push({
        [shiftReportTitleList[key].id]: "",
        Count: `No ${key.charAt(0).toUpperCase() + key.slice(1)} Summary`,
        Amount: "",
      });
      emptyTotal["Count"] = "0.00";
      emptyTotal["Amount"] = "0.00";
    } else if (key === "paid-in-out") {
      emptyHeader.push("Date", "Pay In/Out", "Amount");
      emptyNodeData.push({
        Date: "",
        "Pay In/Out": "No Paid Ins/Outs",
        Amount: "",
      });
      emptyTotal["Amount"] = "0.00";
    } else if (key === "void-transaction") {
      emptyHeader.push("Date", "User", "Amount");
      emptyNodeData.push({
        Date: "",
        User: "No Void Transactions",
        Amount: "",
      });
      emptyTotal["Amount"] = "0.00";
    } else if (key === "cancelled-receipts") {
      emptyHeader.push("Date", "User", "Amount");
      emptyNodeData.push({
        Date: "",
        User: "No cancelled Sales",
        Amount: "",
      });
      emptyTotal["Amount"] = "0.00";
    } else if (key === "discount") {
      emptyHeader.push("User", "Discount", "Amount");
      emptyNodeData.push({
        User: "",
        Discount: "No Discounts",
        Amount: "",
      });
      emptyTotal["Amount"] = "0.00";
    }

    return { emptyHeader, emptyNodeData, emptyTotal };
  };

  useEffect(() => {
    let allPdfHeaderList: any = {};
    let allExcelHeaderList: any = {};
    let mergeList: any = {};
    let mergeListPdfExcel: any = {};
    const count = 0;
    if (!_.isEmpty(shiftReportNode)) {
      // Iterating Shift report data.
      Object.entries(shiftReportNode).forEach(([key, ShiftDetails]: any) => {
        if (key !== "takings") {
          let nodeData: any = [];
          let header: any = [];
          let total: any = {};
          total[shiftReportTitleList[key].id] = "Total";
          if (_.isEmpty(ShiftDetails)) {
            const { emptyHeader, emptyNodeData, emptyTotal } =
              handleEmptyShiftDetails(key);

            header = emptyHeader;
            nodeData = emptyNodeData;
            total = { ...total, ...emptyTotal };
          } else {
            let nodeDataList: any = [];
            // Create a pay in out table
            if (key === "paid-in-out") {
              total["Amount"] = 0;
              // Set the header of paid-in-out table
              header.push(
                "Date",
                "Time",
                "Type",
                "Pay In/Out",
                "Remarks",
                "Amount",
              );
              // Set the table data of paid-in-out table
              ShiftDetails.map((paidInOutData: any, index: any) => {
                nodeDataList = handlePaidInOutTable(
                  paidInOutData,
                  index,
                  nodeData,
                  total,
                  ShiftDetails,
                );
              });
              nodeData = nodeDataList.nodeData;
              total = nodeDataList.total;
            } else if (
              key === "void-transaction" ||
              key === "cancelled-receipts"
            ) {
              //Create void-transaction and Cancelled-receipts table
              total["Amount"] = 0;
              // Set the header of void-transaction and Cancelled-receipts table
              header.push("Date", "Time", "User", "Amount");
              // Set the table data of void-transaction and Cancelled-receipts table
              ShiftDetails.map(
                (voidTransactionOrCancelledReceiptData: any, index: any) => {
                  nodeDataList = handleVoidTransactionAndCancelledSaleTable(
                    voidTransactionOrCancelledReceiptData,
                    index,
                    nodeData,
                    total,
                    ShiftDetails,
                  );
                },
              );
              nodeData = nodeDataList.nodeData;
              total = nodeDataList.total;
            } else if (key === "discount") {
              //Create discount table
              total["Discounted Amount"] = 0;
              total["Order Count"] = 0;
              total["Order Total"] = 0;
              // Set the header of discount table
              header.push(
                "User",
                "Order Count",
                "Discounted Amount",
                "Order Total",
              );
              // Set the table data of discount table
              ShiftDetails.map((discountData: any, index: any) => {
                nodeDataList = handleDiscountTable(
                  discountData,
                  index,
                  nodeData,
                  total,
                  ShiftDetails,
                );
              });
              nodeData = nodeDataList.nodeData;
              total = nodeDataList.total;
            } else {
              header.push(shiftReportTitleList[key].id);
              // Iterate Object
              Object.entries(ShiftDetails).forEach(
                ([objectKey, objectData]: any, index: number) => {
                  if (key === "other-payments") {
                    nodeDataList = handleShiftSummery(
                      objectData,
                      index,
                      nodeData,
                      header,
                      total,
                      ShiftDetails,
                      key,
                      objectKey,
                      count,
                    );
                    nodeData = nodeDataList.nodeData;
                    total = nodeDataList.total;
                  } else if (key === "vat-audit") {
                    nodeDataList = handleVatAuditTable(
                      objectData,
                      index,
                      nodeData,
                      header,
                      total,
                      key,
                      objectKey,
                    );
                    nodeData = nodeDataList.nodeData;
                    total = nodeDataList.total;
                  } else if (
                    key === "mode" ||
                    key === "platform" ||
                    key === "channel"
                  ) {
                    const modeLength = Object.values(ShiftDetails).length;
                    nodeDataList = handleModeTable(
                      objectData,
                      index,
                      nodeData,
                      header,
                      total,
                      key,
                      objectKey,
                      modeLength,
                    );
                    nodeData = nodeDataList.nodeData;
                    total = nodeDataList.total;
                  } else if (key === "summary") {
                    nodeDataList = handleSummaryTable(
                      objectData,
                      nodeData,
                      total,
                      objectKey,
                      header,
                      index,
                      shiftReportNode,
                    );

                    nodeData = nodeDataList.nodeData;
                    header = nodeDataList.header;
                    total = nodeDataList.total;
                  } else if (key === "banking") {
                    nodeDataList = handleBankingTable(
                      objectData,
                      nodeData,
                      total,
                      objectKey,
                      header,
                      index,
                      shiftReportNode,
                    );
                    nodeData = nodeDataList.nodeData;
                    header = nodeDataList.headers;
                    total = nodeDataList.total;
                  }
                },
              );
            }
            /* If vat-audit is the key, it is sorted using the VAT Rate header. */
            if (key === "vat-audit") {
              nodeData = nodeData.sort(
                (a: any, b: any) =>
                  parseFloat(a["VAT Rate"].toString().replace("%", "")) -
                  parseFloat(b["VAT Rate"].toString().replace("%", "")),
              );
            }
          }

          const pdfHeaderList: any = [];
          const excelHeaderList: any = [];

          /* Inserting header for PDF and Excel tables.  */
          if (!_.isEmpty(header)) {
            header.map((data: any) => {
              if (key === "paid-in-out") {
                // The remarks header is not required for the Paid in out table.
                if (data !== "Remarks") {
                  pdfHeaderList.push({ title: data, field: data });
                  excelHeaderList.push({
                    header: data,
                    key: data,
                    width: 52,
                    height: 68,
                  });
                }
              } else {
                pdfHeaderList.push({ title: data, field: data });
                excelHeaderList.push({
                  header: data,
                  key: data,
                  width: 52,
                  height: 68,
                });
              }
            });
          }

          allExcelHeaderList[shiftReportTitleList[key].title] = excelHeaderList;
          allPdfHeaderList[shiftReportTitleList[key].title] = pdfHeaderList;

          if (key === "paid-in-out") {
            /* For payInOut table, entering pay_in or pay_out instead of pay_in chip and pay_out chip. */
            if (nodeData[0]["Pay In/Out"] !== "No Paid Ins/Outs") {
              nodeData.map((data: any) => {
                data["Pay In/Out"] =
                  data["Pay In/Out"] !== "No Paid Ins/Outs"
                    ? data["Pay In/Out"].props.label
                    : data["Pay In/Out"];
              });
            }

            let footer: any = [];
            /* The sum of types and the sum of pay_in and pay_out are entered separately in the total object.
            ex:- total = {Amount: "-179.00", Cash In: 90, Cash Out: -25, Delivery In: 20, Online Payment: -67,
            Return to Pay: 61, Safe Drop: -235, Supplier: -23, payIn: "171.00", payOut: "-350.00"}.
            Here the data is entered into the pdf and excel tables. */

            Object.entries(total).forEach(([key, value]: any) => {
              if (
                key !== "Date" &&
                key !== "Amount" &&
                key !== "payIn" &&
                key !== "payOut"
              ) {
                /* This will insert decimal points. */
                if (typeof value === "number") {
                  /* To decimalize negative values, the minus sign is removed and decimalized. */
                  if (value < 0) {
                    const removeMinusMark = replaceCommon(
                      Math.abs(value).toString(),
                      "-",
                      "",
                    );

                    footer.push({
                      Date: key,
                      Amount: `-${convertDecimalPoint(removeMinusMark)}`,
                    });
                  } else {
                    footer.push({
                      Date: key,
                      Amount: convertDecimalPoint(value.toString()),
                    });
                  }
                }
              }
            });

            // Adding the sum of pay_in and pay_out to the footer.
            if (nodeData[0]["Pay In/Out"] !== "No Paid Ins/Outs") {
              footer.push({ Date: "Pay In", Amount: total["payIn"] });
              footer.push({ Date: "Pay Out", Amount: total["payOut"] });
            }
            //Inserting the total amount into the footer.
            footer.push({ Date: "Total", Amount: total["Amount"] });

            // For pdf and excel tables, insert the table body and footer.
            mergeListPdfExcel[shiftReportTitleList[key].title] = [
              ...nodeData,
              ...footer,
            ];

            mergeListPdfExcel["paidInOutNodesLength"] = nodeData.length;
          } else if (key === "void-transaction") {
            let footer: any = [];

            Object.entries(total).forEach(
              ([key, value]: any, index: number) => {
                if (key !== "Date" && key !== "Amount") {
                  footer.push({ Date: key, Amount: value });
                }
              },
            );
            footer.push({ Date: total["Date"], Amount: total["Amount"] });
            mergeListPdfExcel[shiftReportTitleList[key].title] = [
              ...nodeData,
              ...footer,
            ];

            mergeListPdfExcel["voidTransactionNodesLength"] = nodeData.length;
            mergeListPdfExcel["voidTransactionFooterLength"] = footer.length;
          } else if (key === "cancelled-receipts") {
            let footer: any = [];

            Object.entries(total).forEach(
              ([key, value]: any, index: number) => {
                if (key !== "Date" && key !== "Amount") {
                  footer.push({ Date: key, Amount: value });
                }
              },
            );
            footer.push({ Date: total["Date"], Amount: total["Amount"] });
            mergeListPdfExcel[shiftReportTitleList[key].title] = [
              ...nodeData,
              ...footer,
            ];

            mergeListPdfExcel["cancelledSaleNodesLength"] = nodeData.length;
            mergeListPdfExcel["cancelledSaleFooterLength"] = footer.length;
          } else if (
            key === "vat-audit" ||
            key === "other-payments" ||
            key === "discount" ||
            key === "mode" ||
            key === "platform" ||
            key === "channel"
          ) {
            mergeListPdfExcel[shiftReportTitleList[key].title] = [
              ...nodeData,
              total,
            ];
          } else if (key === "summary") {
            mergeListPdfExcel[shiftReportTitleList[key].title] = [...nodeData];
          } else if (key === "banking") {
            let footer: any = [];
            footer.push({
              Currency: "Declared Cash Total",
              Count: "",
              Total: parseFloat(shiftReportNode.banking.declaredCash).toFixed(
                2,
              ),
            });
            mergeListPdfExcel[shiftReportTitleList[key].title] = [
              ...nodeData,
              ...footer,
            ];

            mergeListPdfExcel[`Banking_payInOut`] = nodeData["payInOut"];
            mergeListPdfExcel[`Banking_info`] = nodeData["info"];
            mergeListPdfExcel[`Takings_info`] = nodeData["takings"];
          }

          mergeListPdfExcel[`${shiftReportTitleList[key].title}_tableName`] =
            shiftReportTitleList[key].label;

          /* Here the data is entered into the website table. */
          if (key === "void-transaction" || key === "cancelled-receipts") {
            let footer: any = [];
            Object.entries(total).forEach(
              ([key, value]: any, index: number) => {
                if (key !== "Date" && key !== "Amount") {
                  footer.push({ Date: key, Amount: value });
                }
              },
            );
            footer.push({ Date: total["Date"], Amount: total["Amount"] });

            mergeList[shiftReportTitleList[key].title] = {
              footer: footer,
              header: header,
              data: nodeData,
              tableName: shiftReportTitleList[key].label,
            };
          } else if (key === "paid-in-out") {
            let footer: any = [];

            Object.entries(total).forEach(
              ([key, value]: any, index: number) => {
                if (
                  key !== "Date" &&
                  key !== "Amount" &&
                  key !== "payIn" &&
                  key !== "payOut"
                ) {
                  if (typeof value === "number") {
                    if (value < 0) {
                      const removeMinusMark = Math.abs(value)
                        .toString()
                        .replace("-", "");

                      footer.push({
                        Date: key,
                        Amount: `-${convertDecimalPoint(removeMinusMark)}`,
                      });
                    } else {
                      footer.push({
                        Date: key,
                        Amount: convertDecimalPoint(value.toString()),
                      });
                    }
                  } else {
                    footer.push({ Date: key, Amount: value });
                  }
                }
              },
            );
            if (nodeData[0]["Pay In/Out"] !== "No Paid Ins/Outs") {
              footer.push({ Date: "payIn", Amount: total["payIn"] });
              footer.push({ Date: "payOut", Amount: total["payOut"] });
            }

            footer.push({ Date: "Total", Amount: total["Amount"] });
            mergeList[shiftReportTitleList[key].title] = {
              footer: footer,
              header: header,
              data: nodeData,
              tableName: shiftReportTitleList[key].label,
            };
          } else {
            mergeList[shiftReportTitleList[key].title] = {
              footer: [total],
              header: header,
              data: nodeData,
              tableName: shiftReportTitleList[key].label,
            };
          }
        }
      });

      const { basedOnShiftHeader, basedOnShiftTableName } =
        handleBreakDownShift();

      // Here, the shift id in the selected shift data list is removed.
      const removedShiftId = selectedList.map(({ id, ...rest }: any) => {
        return rest;
      });
      // Entering the data of the selected shift_id, header, and table name into the variable.
      mergeList["Breakdown of Shifts"] = {
        footer: [],
        header: basedOnShiftHeader,
        data: removedShiftId,
        tableName: basedOnShiftTableName,
      };
      if (deviceIdErr) {
        allPdfHeaderList["Breakdown of Shifts"] = [
          {
            title: "Location",
            field: "Location",
          },
          {
            title: "Terminal",
            field: "Terminal",
          },
          {
            title: "Start Date",
            field: "Start Date",
          },
          {
            title: "End Date",
            field: "End Date",
          },
          {
            title: "Duration",
            field: "Duration",
          },
        ];
        allExcelHeaderList["Breakdown of Shifts"] = [
          { header: "Location", height: 68, key: "Location", width: 52 },
          { header: "Terminal", height: 68, key: "Terminal", width: 52 },
          { header: "Start Date", height: 68, key: "Start Date", width: 52 },
          { header: "End Date", height: 68, key: "End Date", width: 52 },
          { header: "Duration", height: 68, key: "Duration", width: 52 },
        ];
      } else {
        allPdfHeaderList["Breakdown of Shifts"] = [
          {
            title: "Location",
            field: "Location",
          },
          {
            title: "Terminal",
            field: "Terminal",
          },
          {
            title: "TID",
            field: "TID",
          },
          {
            title: "Start Date",
            field: "Start Date",
          },
          {
            title: "End Date",
            field: "End Date",
          },
          {
            title: "Duration",
            field: "Duration",
          },
        ];
        allExcelHeaderList["Breakdown of Shifts"] = [
          { header: "Location", height: 68, key: "Location", width: 52 },
          { header: "Terminal", height: 68, key: "Terminal", width: 52 },
          { header: "TID", height: 68, key: "TID", width: 52 },
          { header: "Start Date", height: 68, key: "Start Date", width: 52 },
          { header: "End Date", height: 68, key: "End Date", width: 52 },
          { header: "Duration", height: 68, key: "Duration", width: 52 },
          { header: "", height: 68, key: "", width: 52 },
          { header: "", height: 68, key: "", width: 52 },
          { header: "", height: 68, key: "", width: 52 },
        ];
      }

      mergeListPdfExcel["Breakdown of Shifts"] = removedShiftId;
      mergeListPdfExcel["Breakdown of Shifts_tableName"] =
        "Breakdown of shifts in this report";

      /* Changing the order of tables. */
      let finalMergeList: any = {};
      let finalMergeListPdfExcel: any = {};
      Object.values(shiftReportTitleList).map((data: any) => {
        finalMergeList[data.title] = mergeList[data.title];
        finalMergeListPdfExcel[data.title] = mergeListPdfExcel[data.title];
        finalMergeListPdfExcel[`${data.title}_tableName`] =
          mergeListPdfExcel[`${data.title}_tableName`];
      });
      Object.assign(finalMergeList, mergeList);
      Object.assign(finalMergeListPdfExcel, mergeListPdfExcel);

      setPdfHeader(allPdfHeaderList);
      setExcelHeaders(allExcelHeaderList);
      setNodes(finalMergeList);
      setInitialNode(finalMergeList);
      setNodesPdfExcel(finalMergeListPdfExcel);
      setNodesPdfExcelInitial(finalMergeListPdfExcel);
    }
  }, [shiftReportNode]);
  /* Open pdf and excel menu. */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close pdf and excel menu. */
  const handleCloseExport = () => {
    setAnchorEl(null);
  };

  /* Close shift table modal */
  const handleCloseShiftTableModal = () => {
    setNodes([]);
    setInitialNode([]);
    handleCloseModal();
  };

  /* After clicking on a sales row, the data of that row is entered into a state. */
  const handleBreakdownShift = (e: any) => {
    const cloneNodes = _.cloneDeep(nodes);
    const cloneNodesPdfExcel = _.cloneDeep(nodesPdfExcel);
    const { checked } = e.target;
    if (!checked) {
      cloneNodes["Breakdown of Shifts"].data = [];
      cloneNodes["Breakdown of Shifts"].header = [];
      cloneNodesPdfExcel["Breakdown of Shifts"] = [];
      setNodes(cloneNodes);
      setNodesPdfExcel(cloneNodesPdfExcel);
    } else {
      setNodes(initialNode);
      setNodesPdfExcel(nodesPdfExcelInitial);
    }
    setIsShowBreakdownShift(checked);
  };

  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleCloseShiftTableModal}
      PaperProps={{
        style: {
          borderRadius: 10,
          border: `1px solid ${theme.palette.background.entity_border}`,
          backgroundColor: theme.palette.background.entity_highlight_background,
        },
      }}
    >
      {!isShiftSummaryReportLoading ? (
        <div>
          <DialogTitle id="alert-dialog-title">
            <Grid container style={{ placeItems: "center" }}>
              <Grid item sm={6} xs={12}>
                <Typography variant="h6">SHIFT SUMMERY REPORT</Typography>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ButtonCommon
                  variant="contained"
                  color="red"
                  className={classes.buttonStyle}
                  onClick={handleCloseShiftTableModal}
                >
                  Cancel
                </ButtonCommon>

                <div style={{ paddingLeft: "8px" }}>
                  <MenuButtonCommon
                    id="basic-button"
                    variant="contained"
                    style={{
                      fontSize: 11,
                      height: "38px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                    color="blue"
                    aria-controls={openExport ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openExport ? "true" : undefined}
                    onClick={handleClick}
                    disabled={_.isEmpty(nodes)}
                  >
                    <GetAppIcon />
                    <Typography
                      variant="body1"
                      style={{ paddingLeft: "16px", color: "white" }}
                    >
                      Export
                    </Typography>
                  </MenuButtonCommon>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openExport}
                    onClose={handleCloseExport}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border}`,
                      },
                    }}
                    style={{ top: "56px" }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handlePdf(filterDetails, pdfHeader, nodesPdfExcel);
                        handleCloseExport();
                      }}
                    >
                      Export as PDF
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleExcel(
                          excelHeaders,
                          nodesPdfExcel,
                          filterDetails,
                          headersLists,
                        );
                        handleCloseExport();
                      }}
                    >
                      Export as CSV
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          {!_.isEmpty(nodes) ? (
            <>
              {Object.entries(nodes).map(
                ([key, value]: any, index: any) =>
                  key !== "undefined" && (
                    <div style={{ margin: "6px" }}>
                      {key === "Breakdown of Shifts" ? (
                        <BreakDownOfShiftTableInfo
                          shiftReportNode={value}
                          handleBreakdownShift={handleBreakdownShift}
                          isShowBreakdownShift={isShowBreakdownShift}
                        />
                      ) : (
                        <>
                          {key === "Banking" ? (
                            <TableInfo
                              shiftReportNode={value}
                              platform={nodes.Platform}
                            />
                          ) : key === "Summary" ? (
                            <BankingAndSummeryInfo shiftReportNode={value} />
                          ) : (
                            <TableInfo shiftReportNode={value} />
                          )}
                        </>
                      )}
                    </div>
                  ),
              )}
            </>
          ) : (
            <div
              style={{
                width: "100%",
                margin: "auto",
                marginTop: "5%",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h4" align="center">
                No shift available.
              </Typography>
            </div>
          )}

          <DialogActions>
            {/* <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button> */}
            {/* <Button onClick={handleSubmit} color="primary" autoFocus>
            Apply
          </Button> */}
          </DialogActions>
        </div>
      ) : (
        <ModalTableLoading />
      )}
    </Dialog>
  );
};

export default Generate;
