import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import {
  fetchAllChain,
  fetchChainCurrentLocation,
} from "../../../../services/locationApp/chainConfiguration";
import { fetchAllScheduledTask } from "../../../../services/locationApp/scheduledTaskConfiguration";
import ScheduleTaskConfigurationNode from "./ScheduleTaskConfigurationNode";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * ScheduledTaskConfiguration Component
 *
 * This component handles the chain configuration for locations in the application.
 * It fetches and displays data related to chains and their associated locations,
 * allowing the user to view and manage chain configurations.
 *
 * Key Features:
 * - Fetches and displays all available filter locations and chains associated with a specific location.
 * - Displays a detailed view of chain configuration using child components like `InventoryDetailsDefault` and `ChainConfigurationNode`.
 * - Shows loading skeletons during data fetching and provides user-friendly alerts for errors and success messages.
 * - Includes alert dialogs for specific scenarios such as stock activation and stock configuration changes.
 * - Utilizes Material-UI for UI components and styling, along with state management using React hooks.
 * - Enforces user permissions via the `withAuthority` Higher-Order Component (HOC) to ensure only authorized users can access the component.
 */
const ScheduledTaskConfiguration: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [scheduleTaskNode, setScheduleTaskNode] = useState<any>([]);
  const [
    successfullyChangeStockConfiguration,
    setSuccessfullyChangeStockConfiguration,
  ] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");

  const match: any = useRouteMatch();

  // useEffect to fetch locations and set the document title
  useEffect(() => {
    document.title = "Location - Scheduled Task Configuration";
    getAllFilterLocation();
  }, []);

  /**
   * Fetches all chains for a given location and updates the chain node data.
   *
   * This function retrieves all chain details associated with the location ID
   * from the route parameters (`match.params.locationId`). It updates the
   * `scheduleTaskNode` state with the fetched data and subsequently calls
   * `handleGetChainDetails` to fetch additional chain details. In case of an error,
   * it sets a generic error message and ensures the skeleton loading animation is stopped.
   */
  const handleGetAllScheduledTask = async () => {
    // Start of the asynchronous function to fetch all chain data.
    try {
      // Attempt to fetch all chains using the location ID from the route parameters.
      const res = await fetchAllScheduledTask(match.params.locationId);

      // Update the `scheduleTaskNode` state with the fetched chain data.
      setScheduleTaskNode(res.data);

      // Ensure the skeleton loading animation is disabled even in case of an error.
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the fetch, set a generic error message to display to the user.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);

      // Ensure the skeleton loading animation is disabled even in case of an error.
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Fetches all filterable locations and updates the location selector list.
   *
   * This function retrieves a list of locations using the `fetchAllFilterLocations` service
   * and processes the data to populate a dropdown or selector list for the UI. The list includes
   * a default entry for "Master Location Not Required" and additional entries for each location's
   * business display name. It also triggers fetching all chain details using `handleGetAllSheduledTask`.
   */
  const getAllFilterLocation = async () => {
    // Start of the asynchronous function to fetch all filterable locations.
    try {
      // Fetch the list of all filterable locations.
      const res = await fetchAllFilterLocations("");

      // Initialize an empty array to hold the processed location data.
      let locationList: any = [];

      // Check if the fetched data is not empty.
      if (!_.isEmpty(res.data.data)) {
        // Add a default entry for "Master Location Not Required" to the location list.
        locationList.push({
          id: match.params.locationId, // Use the location ID from the route parameters.
          label: "Master Location Not Required", // Display label for the default entry.
        });

        // Iterate over the fetched location data to build the location list.
        res.data.data.forEach((locationData: Location) => {
          locationList.push({
            id: locationData.id, // Use the location's unique ID.
            label: locationData.businessDisplayName, // Use the business display name as the label.
          });
        });
      }
      // Trigger fetching all chain details using the `handleGetAllScheduledTask` function.
      handleGetAllScheduledTask();

      // Update the `locationSelectorList` state with the processed location data.
      setLocationSelectorList(locationList);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  return (
    <>
      <Box>
        <InventoryDetailsDefault
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          topic=""
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
        >
          <ScheduleTaskConfigurationNode
            scheduleTaskNode={scheduleTaskNode}
            setScheduleTaskNode={setScheduleTaskNode}
          />
        </InventoryDetailsDefault>
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>

      {successfullyChangeStockConfiguration && (
        <AlertDialog
          open={successfullyChangeStockConfiguration}
          title="Success"
          desc="Successfully change stock configuration."
          severity="success"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setSuccessfullyChangeStockConfiguration(false);
          }}
          color="secondary"
        />
      )}
    </>
  );
};

export default withAuthority(
  ScheduledTaskConfiguration,
  Authorities.STOCK_CONFIGURATION_READ,
);
