import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  }),
);

export interface StockLevelModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleUpdate: any;
  isLoading: boolean;
  locationNode: any;
  handleChangePostCodeLocation: any;
  selectedPostcode: any;
  postCodeNode: any;
  editPostCode: any;
}

const EditPostCode: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  handleUpdate,
  isLoading,
  locationNode,
  handleChangePostCodeLocation,
  selectedPostcode,
  postCodeNode,
  editPostCode,
}) => {
  const [postcode, setPostcode] = useState("");
  const [postcodeInitial, setPostcodeInitial] = useState("");
  const [selectedPostcodeInitial, setSelectedPostcodeInitial] = useState("");
  const [postCodeErrorMessage, setPostCodeErrorMessage] = useState("");

  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const { postCode, locationId } = editPostCode;
      setPostcode(postCode);
      setSelectedPostcodeInitial(selectedPostcode.id);
      setPostcodeInitial(postCode);
      setIsError(false); // Reset error when modal opens
      setPostCodeErrorMessage("");
    }
  }, [isOpen]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostcode(e.target.value);
    if (e.target.value) {
      setIsError(false);
      setPostCodeErrorMessage("");
    }
  };

  const validatePostcode = (value: string): boolean => {
    const regex = /^[A-Z][A-Z0-9]*\d$/;
    return regex.test(value);
  };

  const handleUpdatePostcode = () => {
    let isError = false;
    postCodeNode.map((postCodeNode: any) => {
      if (
        postcode.trim() === postCodeNode.postCode &&
        selectedPostcode.id === selectedPostcodeInitial
      ) {
        isError = true;
      }
    });

    if (isError) {
      setIsError(true);
      return setPostCodeErrorMessage("Postcode already exist");
    }

    if (!postcode.trim() || Object.keys(selectedPostcode).length === 0) {
      setIsError(true);
    } else {
      if (!validatePostcode(postcode)) {
        setIsError(true);
        setPostCodeErrorMessage(
          "Invalid postcode format. Ensure it starts with a letter and ends with a number.",
        );
        return;
      }

      setIsError(false);
      handleUpdate(postcode.trim(), postcodeInitial, selectedPostcodeInitial);
    }
  };
  const classes = useStyles();

  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>{`Edit postcode - ${postcodeInitial}`}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextfieldDefaultNew
              id="postcode"
              name="postcode"
              style={{ width: "100%" }}
              label="Postcode"
              onChange={handleNameChange}
              value={postcode}
              type="text"
            />
            {((isError && !postcode) || (isError && postCodeErrorMessage)) && (
              <ValidationMessage
                message={postCodeErrorMessage || "Name is required"}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              size={"small"}
              id="locationSelectGlobal"
              color="inherit"
              options={locationNode}
              value={selectedPostcode}
              getOptionLabel={(option: any) => option.label || ""}
              disableClearable
              onChange={handleChangePostCodeLocation}
              classes={{ root: classes.autoComplete }}
              popupIcon={<ArrowDropDownIcon color={"inherit"} />}
              renderOption={(props: any) => {
                return (
                  <Grid
                    container
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: 0,
                      height: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.titleColor}
                      >
                        {props.label}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  color="inherit"
                  {...params}
                  label=""
                  placeholder="Select Department"
                  variant="outlined"
                />
              )}
            />
            {isError && Object.keys(selectedPostcode).length === 0 && (
              <ValidationMessage message="Location is required" />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpen(false)}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          disabled={isLoading}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          onClick={handleUpdatePostcode}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.UPDATE_BUTTON_COLOR}
          disabled={
            isLoading ||
            (postcodeInitial === postcode &&
              selectedPostcode.id === selectedPostcodeInitial)
          }
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Update"
          )}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default EditPostCode;
