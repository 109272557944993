import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Chip,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import TextfieldDefaultNew from "../../../../components/textField/TextfieldDefaultNew";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    chipContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
    },
    chip: {
      backgroundColor: theme.palette.background.entity_highlight_background,
    },
  }),
);

export interface StockLevelModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleCreate: any;
  isLoading: boolean;
  locationNode: any;
  handleChangePostCodeLocation: any;
  selectedPostcode: any;
  postCodeNode: any;
}

const CreateNewChain: React.FC<StockLevelModalProps> = ({
  isOpen,
  setIsOpen,
  handleCreate,
  isLoading,
  locationNode,
  handleChangePostCodeLocation,
  selectedPostcode,
  postCodeNode,
}) => {
  const [postcode, setPostcode] = useState("");
  const [postcodeChips, setPostcodeChips] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [postcodeErrorMessage, setPostcodeErrorMessage] = useState("");
  const [locationErrorMessage, setLocationErrorMessage] = useState("");

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      setPostcode("");
      setPostcodeChips([]);
      setIsError(false);
      setValidationError("");
    }
  }, [isOpen]);

  const validatePostcode = (value: string): boolean => {
    const regex = /^[A-Z][A-Z0-9]*\d$/;
    return regex.test(value);
  };

  const handlePostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.toUpperCase().replace(/\s/g, "");
    setPostcode(input);

    if (input && !validatePostcode(input)) {
      setPostcodeErrorMessage(
        "Postcode must be alphanumeric, start with a letter, and end with a number.",
      );
    } else {
      setPostcodeErrorMessage("");
    }
    setIsError(false);
  };

  const handleAddPostcodeChip = () => {
    if (!validatePostcode(postcode)) {
      setIsError(true);
      setPostcodeErrorMessage(
        "Invalid postcode format. Ensure it starts with a letter and ends with a number.",
      );
      return;
    }

    if (postcodeChips.includes(postcode)) {
      setIsError(true);
      setPostcodeErrorMessage("This postcode prefix has already been added.");
      return;
    }
    setPostcodeChips((prev) => [...prev, postcode]);
    setPostcode("");
    setPostcodeErrorMessage("");
  };

  const handleChipDelete = (chipToDelete: string) => {
    setPostcodeChips((prev) => prev.filter((chip) => chip !== chipToDelete));
  };

  const handleCreateNewPostcode = () => {
    if (postcodeChips.length === 0 && !postcode.trim()) {
      setIsError(true);
      setPostcodeErrorMessage("Postcode is required.");
      if (Object.values(selectedPostcode).length === 0) {
        setIsError(true);
        setLocationErrorMessage("Location is required.");
        return;
      }
      return;
    }

    if (Object.values(selectedPostcode).length === 0) {
      setIsError(true);
      setLocationErrorMessage("Location is required.");
      return;
    }

    if (postcode.trim() && !validatePostcode(postcode)) {
      setIsError(true);
      setPostcodeErrorMessage(
        "Invalid postcode format. Ensure it starts with a letter and ends with a number.",
      );
      return;
    }

    const postcodesToSubmit =
      postcodeChips.length > 0
        ? postcodeChips
        : [postcode.trim().toUpperCase()];
    let isError = false;
    postCodeNode.map((postCodeNode: any) => {
    
      if (postcodesToSubmit.includes(postCodeNode.postCode)) {
        isError = true;
      }
    });

    if (isError) {
      setIsError(true);
      setPostcodeErrorMessage("This postcode prefix has already been added.");
      return;
    }
    setIsError(false);
    setValidationError("");
    handleCreate(postcodesToSubmit);
  };

  return (
    <DialogCommonDefault
      open={isOpen}
      setOpen={setIsOpen}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>Add Postcodes</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextfieldDefaultNew
              id="postcode"
              name="postcode"
              style={{ width: "100%" }}
              label="Postcode Prefix"
              onChange={handlePostcodeChange}
              value={postcode}
              type="text"
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddPostcodeChip();
                }
              }}
            />
            {postcodeErrorMessage && isError && (
              <ValidationMessage message={postcodeErrorMessage} />
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.chipContainer}>
              {postcodeChips.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={() => handleChipDelete(chip)}
                  className={classes.chip}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              size={"small"}
              id="locationSelectGlobal"
              color="inherit"
              options={locationNode}
              value={selectedPostcode}
              getOptionLabel={(option: any) => option.label || ""}
              disableClearable
              onChange={handleChangePostCodeLocation}
              classes={{ root: classes.autoComplete }}
              popupIcon={<ArrowDropDownIcon color={"inherit"} />}
              renderOption={(props: any) => {
                return (
                  <Grid
                    container
                    {...props}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      zIndex: 0,
                      height: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.titleColor}
                      >
                        {props.label}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  color="inherit"
                  {...params}
                  label=""
                  placeholder="Select Location"
                  variant="outlined"
                />
              )}
            />
            {isError && locationErrorMessage && Object.keys(selectedPostcode).length === 0 && (
              <ValidationMessage message={locationErrorMessage} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => setIsOpen(false)}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "12px" }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
          disabled={isLoading}
        >
          Cancel
        </ButtonCommon>
        <ButtonCommon
          onClick={handleCreateNewPostcode}
          variant="contained"
          style={{ fontSize: 11, width: "120px", marginRight: "14px" }}
          color={buttonColors.CREATE_BUTTON_COLOR}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            "Create"
          )}
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default CreateNewChain;
