import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { buttonColors } from "../../../../utils/enum";
import { useRouteMatch } from "react-router-dom";
import CreateNewChain from "./CreateNewChain";
import {
  createNewPostcode,
  removePostcode,
  updatePostcode,
} from "../../../../services/locationApp/chainConfiguration";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import ChainDetails from "./ChainDetails";
import ChainRemoveModal from "./ChainRemoveModal";
import CardCommon from "../../../../components/card/CardCommon";
import EditPostCode from "./EditPostCode";
import { AxiosError } from "axios";

export interface StockConfigurationNodeProps {
  postCodeNode: any;
  locationNode: any;
  setPostCodeNode: any;
  chainId: any;
  locationObj: any;
  primaryLocationId: any;
}

/**
 * Component: ChainConfigurationNode
 *
 * Description:
 * This component represents a node in the Stock Configuration page.
 * It provides controls for managing stock-related configurations, such as enabling/disabling stock,
 * enabling/disabling master location, selecting the department, and saving changes.
 **/

const ChainConfigurationNode: React.FunctionComponent<
  StockConfigurationNodeProps
> = ({
  postCodeNode,
  locationNode,
  setPostCodeNode,
  chainId,
  locationObj,
  primaryLocationId,
}) => {
  const [openChainCreateModal, setOpenChainCreateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingChain, setIsLoadingChain] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedPostcode, setSelectedPostcode] = useState<any>({});
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editPostCode, setEditPostCode] = useState<any>({});

  const match: any = useRouteMatch();

  const handleOpenCreateNewChain = () => {
    setOpenChainCreateModal(true);
    setSelectedPostcode({});
  };

  const handleCreateNewChain = async (postCode: any) => {
    try {
      setIsLoadingChain(true);
      const data = postCode.map((postCode: any) => ({
        postCode,
        locationId: selectedPostcode.id,
      }));
      const formData = { data: data };
       await createNewPostcode(
        match.params.locationId,
        chainId,
        formData,
      );
      const clone = _.cloneDeep(postCodeNode);
      data.map((data: any) => {
        clone.push({
          postCode: data.postCode,
          locationId: data.locationId,
        });
      });

      setPostCodeNode(clone);
      setSuccess(SUCCESSFULLY_CREATED);
      setIsLoadingChain(false);
      setOpenChainCreateModal(false);
    } catch (error) {
      const err: any = error as AxiosError;
      setIsLoadingChain(false);
      setError(err.response.data.message);
    }
  };

  const handleUpdate = async (
    postCode: string,
    prePostCode: any,
    prevLocationId: any,
  ) => {
    try {
      setIsLoadingChain(true);
      const cloneData = _.cloneDeep(postCodeNode);
      cloneData[index] = {
        postCode: postCode,
        locationId: selectedPostcode.id,
      };
      const data = {
        postcode: postCode,
        locationId: selectedPostcode.id,
        prevPostcode: prePostCode,
        prevLocationId: prevLocationId,
      };
      await updatePostcode(match.params.locationId, chainId, data);
      setPostCodeNode(cloneData);
      setIsOpenEditModal(false);
      setIsLoadingChain(false);
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      setIsLoadingChain(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  const handleChangePostCodeLocation = (event: any, chain: any) => {
    setSelectedPostcode(chain);
  };

  const handleOpenDeleteModal = async (data: any, index: any) => {
    setIsOpenDeleteModal(true);
    setEditPostCode(data);
    setIndex(index);
  };

  const handleOpenEditModal = async (data: any, index: any) => {
    setIsOpenEditModal(true);
    setEditPostCode(data);
    setIndex(index);
    setSelectedPostcode({
      id: data.locationId,
      label: locationObj[data.locationId].label,
    });
  };

  const handleRemovePostcode = async () => {
    try {
      setIsLoadingUpdate(true);
      const data = { postcode: editPostCode.postCode };
      const res = await removePostcode(match.params.locationId, chainId, data);
      setPostCodeNode((postCodeNode: any) => {
        const updatedPostCodeData = [...postCodeNode];
        updatedPostCodeData.splice(index, 1); // Remove the item at the given index
        return updatedPostCodeData; // Update the state with the new array
      });
      setSuccess(SUCCESSFULLY_DELETED);
      // setPostCodeNode({});
      setIsOpenDeleteModal(false);
      setIsLoadingUpdate(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingUpdate(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "end" }}>
          <ButtonCommon
            onClick={handleOpenCreateNewChain}
            variant="contained"
            style={{
              fontSize: 11,
              height: "60px",
              width: "100%",
              textTransform: "none",
              marginRight: "12px",
            }}
            disabled={primaryLocationId !== match.params.locationId}
            color={buttonColors.CREATE_BUTTON_COLOR}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Add a new postcode{" "}
            </Typography>
          </ButtonCommon>
        </Grid>
      </Grid>
      {postCodeNode.length > 0 ? (
        <Grid container style={{ marginTop: "28px" }}>
          <Grid item xs={12}>
            <CardCommon
              backgroundColor={"table_header_background"}
              style={{ height: "40px" }}
            >
              <Grid container>
                <Grid item xs={6} style={{ paddingLeft: "12px" }}>
                  <Typography align="left" style={{ marginTop: "6px" }}>
                    Postcode
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: "12px" }}>
                  <Typography align="right" style={{ marginTop: "6px" }}>
                    Location
                  </Typography>
                </Grid>
              </Grid>
            </CardCommon>
          </Grid>
          {postCodeNode.map((data: any, index: number) => (
            <Grid item xs={12} style={{ marginTop: "4px" }}>
              <CardCommon
                backgroundColor={
                  index % 2 === 0
                    ? "entity_background"
                    : "entity_highlight_background"
                }
                style={{ height: "40px" }}
              >
                <Grid container>
                  <Grid item xs={6} style={{ paddingLeft: "12px" }}>
                    <Typography align="left" style={{ marginTop: "6px" }}>
                      {data.postCode}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      paddingRight: "12px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Typography align="right" style={{ marginTop: "6px" }}>
                      {locationObj[data?.locationId]?.label || data?.locationId}
                    </Typography>
                    <Button
                      size="small"
                      onClick={() => handleOpenEditModal(data, index)}
                      style={{
                        marginLeft: "12px",
                        minWidth: "32px",
                        maxWidth: "32px",
                      }}
                      disabled={primaryLocationId !== match.params.locationId}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      onClick={() => handleOpenDeleteModal(data, index)}
                      size="small"
                      style={{
                        marginLeft: "12px",
                        minWidth: "32px",
                        maxWidth: "32px",
                      }}
                      disabled={primaryLocationId !== match.params.locationId}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Grid>
              </CardCommon>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} style={{ marginTop: "10%" }}>
          <Typography
            variant="h4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            No postcodes available
          </Typography>
        </Grid>
      )}
      <ChainRemoveModal
        isOpen={isOpenDeleteModal}
        setIsOpen={setIsOpenDeleteModal}
        handleRemovePostcode={handleRemovePostcode}
        isLoadingUpdate={isLoadingUpdate}
      />
      <CreateNewChain
        isOpen={openChainCreateModal}
        setIsOpen={setOpenChainCreateModal}
        handleCreate={handleCreateNewChain}
        isLoading={isLoadingChain}
        locationNode={locationNode}
        handleChangePostCodeLocation={handleChangePostCodeLocation}
        selectedPostcode={selectedPostcode}
        postCodeNode={postCodeNode}
      />
      <EditPostCode
        isOpen={isOpenEditModal}
        setIsOpen={setIsOpenEditModal}
        handleUpdate={handleUpdate}
        isLoading={isLoadingChain}
        locationNode={locationNode}
        handleChangePostCodeLocation={handleChangePostCodeLocation}
        selectedPostcode={selectedPostcode}
        postCodeNode={postCodeNode}
        editPostCode={editPostCode}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
    </>
  );
};

// Apply the WithLoading higher-order component to the ChainConfigurationNode component
export default ChainConfigurationNode;
